import { createWebHistory, createRouter } from 'vue-router'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../views/HomeView.vue'),
        },
        {
            path: '/request',
            name: 'request-refund',
            children: [
                {
                    path: '',
                    name: 'request-refund',
                    component: () => import('../views/RequestRefundView.vue'),
                },
                {
                    path: 'form',
                    name: 'request-refund-form',
                    component: () => import('../views/RequestRefundFormView.vue'),
                },
            ],
        },
        {
            path: '/refundableterms',
            name: 'refundableterms',
            component: () => import('../views/TermsView.vue'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'no-found',
            component: () => import('../views/NotFoundView.vue'),
        },
    ],
})

export default router
